import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ======================ar.lpcentre.com==========https://https://api.ar.lpcentre.com//
  //https://lpcentre-en.prokoders-staging-4.xyz/api/  
  baseURL: process.env.VUE_APP_ROOT_API,


  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
